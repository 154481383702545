/* Styles for the input */

/* .petDemoPop.ReactModal__Content {
  width: 100%;
  max-width: 100%;
  margin: 0;
} */
.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
.chip,
.singleChip {
  white-space: normal !important;
}
.rti--input {
  width: 100% !important;
}

/* 16-06-2023 */
.appoint-fields form .d-flex .form-group {
  max-width: calc(100% / 2 - 10px);
  flex: calc(100% / 2 - 10px);
  margin-top: 0;
}

.appoint-fields form .d-flex {
  gap: 20px;
}

.appoint-fields .css-16xfy0z-control,
.css-16xfy0z-control {
  min-height: 57px;
  border: 1px solid #c4c4c4;
}

.ReactModal__Content {
  border: 0px solid #ddd !important;
  border-radius: 10px !important;
  overflow: visible !important;
  width: 100%;
  max-width: max-content;
  margin: auto;
}

.col-sm.appoint-img img {
  max-width: 100%;
  max-height: 590px;
  width: 100%;
  object-fit: cover;
}
/* .appoint-fields {
  overflow-y: auto;
  max-height: 600px;
} */
.appoint-fields::-webkit-scrollbar {
  width: 10px;
}

.appoint-fields::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.appoint-fields::-webkit-scrollbar-thumb {
  background-color: #7a61fa;
  outline: 1px solid slategrey;
}

button.MuiButtonBase-root.MuiButton-containedSecondary:hover {
  color: #7a61fb;
}
body.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  backdrop-filter: blur(8px);
  /* display: grid;
  place-content: center; */
  display: flex;
  align-items: center;
}
.appoint-fields {
  padding: 30px;
  position: relative;
}
.appoint-fields .closeIconRight {
  position: absolute;
  top: 20px;
  right: 15px;
}
.ReactModal__Content {
  padding: 20px !important;
  /* background: #fff; */
}
.col-sm.appoint-fields {
  height: 600px;
  overflow-y: auto;
  padding-bottom: 20px;
}
.appoint-fields form .form-group input[type="radio"] {
  margin-right: 5px;
  display: inline-flex;
}

@media (max-width: 1199px) {
  .navbar .nav-item {
    margin-right: 0;
  }

  .col-sm.appoint-fields {
    max-height: inherit;
    padding-bottom: 10px;
  }
  .ReactModal__Content {
    max-width: 90vw;
    height: 90vh;
    /* overflow-y: auto !important; */
    inset: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .appoint-fields form .form-group .form-label {
    font-size: 14px;
  }

  div#navbarSupportedContent {
    background: #0e2845;
    position: absolute;
    top: 75px;
    width: 100%;
    left: 0;
    padding: 30px 35px;
  }
  footer ul {
    margin-top: 20px;
  }

  .MuiDialog-root .MuiDialog-container.MuiDialog-scrollBody {
    padding: 0 20px;
  }

  .MuiTypography-h2,
  .MuiTypography-h3.MuiTypography-colorSecondary {
    font-size: 24px;
    margin: 0;
  }

  .MuiPaper-elevation1.MuiPaper-rounded {
    border: none;
    box-shadow: none;
  }
  .MuiStepper-horizontal {
    padding: 0;
  }

  div#simple-tabpanel-1,
  .MuiPaper-elevation1.MuiPaper-rounded {
    padding: 20px 0 !important;
  }
  /* .navbar .nav-item .nav-link:not(:last-child) {
    margin-bottom: 30px;
} */
  ul.navbar-nav {
    gap: 20px 0;
  }
  ul.navbar-nav + div {
    margin-top: 25px;
  }
  button:focus:not(:focus-visible) {
    box-shadow: none;
  }
  .navbar-toggler {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  /* .servicesModal::-webkit-scrollbar-thumb {
    background-color: #7a61fa;
    outline: 1px solid slategrey;
  }
  .col-sm.appoint-fields {
    height: auto;
    height: calc(100vh - 100px);
  } */
  .appoint-fields form .d-flex {
    gap: 10px;
    flex-wrap: wrap;
  }
  .appoint-fields form .d-flex .form-group {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  div#navbarSupportedContent {
    top: 66px;
    padding: 20px 20px 30px;
  }
  section.first-banner .col-lg-6.mt-5.pt-5.px-5 {
    padding: 0 20px !important;
  }
  .second-banner .banner2-content .story {
    margin-bottom: 0;
  }
  .second-banner .banner2-content .story,
  .banner1-content .appointment-btn {
    font-size: 14px;
  }
  .banner1-content p {
    font-size: 16px;
  }
  .swiper.swiper-initialized.swiper-horizontal {
    height: 400px;
  }
  /* .col-sm.appoint-fields {
    height: auto;
  } */
  .appoint-fields {
    padding: 10px;
  }
  ul.navbar-nav + div .header-btn {
    width: 100%;
  }

  ul.navbar-nav + div {
    flex-wrap: wrap;
  }

  .header-btn .btn {
    width: 100%;
    height: 48px;
  }
}
