:root {
  --green: #1ac89c;
  --blue: #1976f9;
}
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  color: #000;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* nav.navbar .container {
  width: 100%;
  max-width: 1350px;
} */

.logo-img {
  height: 50px;
}
@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1240px;
  }
}
/* navbar css=========================== */
.navbar .nav-item {
  margin-right: 0;
  border: 1px solid #ffffff00;
  border-radius: 30px;
  padding: 6px 24px;
}
.banner1-content .banner1-btn button.btn.appointment-btn svg {
  width: 15px;
  height: 12px;
  margin-left: 13px;
}
.banner1-content
  .banner1-btn
  .header-btn
  button.btn.start-btn.btn-green.appointment-btn {
  border-radius: 30px;
  border-color: var(--green);
}
.banner1-content
  .banner1-btn
  .header-btn
  button.btn.start-btn.btn-green.appointment-btn:hover {
  border-color: #095dd5;
}
ul.navbar-nav {
  margin-right: 24px;
}
.banner1-content .banner1-btn button.btn.appointment-btn svg path {
  stroke: #fff;
}
body.services-hover ul.navbar-nav li:first-child {
  background: #fff;
  z-index: 99;
}

body.services-hover ul.navbar-nav li:first-child a {
  color: #095dd5;
}
body.services-hover ul.navbar-nav li:first-child svg path {
  fill: #095dd5;
}

.navbar .nav-item .nav-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #fff;
  position: relative;
  display: inline-block;
}

.nav-link::before {
  /* content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  right: 0;
  background: #fff;
  bottom: 0;
  transition: 0.3s ease all; */
}
.nav-link.active::before,
.nav-link:hover::before {
  left: 0;
  right: auto;
  width: 100%;
}

.fixed-top.shadow {
  background: linear-gradient(
    90deg,
    #9647fa 5.66%,
    #1875f8 48.9%,
    #1acc96 100%
  );
}

.header-btn :where(.start-btn, .login-btn) {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 9px 28px 9px 28px;
  color: #fff;
  border-radius: 30px;
}
.btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.btn::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  right: 0;
  bottom: 0;
  transition: 0.3s ease all;
  background-color: var(--green);
  z-index: -1;
}
.btn:hover::before {
  left: 0;
  right: auto;
  width: 100%;
}
.btn:hover {
  color: #fff;
  border-color: var(--green);
}
.header-btn :where(.start-btn, .login-btn):hover {
  background: var(--green);
}
.dots_group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.text-blue {
  font-family: "Comfortaa", cursive;
  color: #1976f9;
  font-weight: 700;
}

.fixed-top {
  animation: fadeIn;
  transition: all 5s linear !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* navbar css=========================== */
/* first banner css============================ */
.first-banner {
  background: url(./Components/assets/Banner-home.jpg) no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}
.first-banner .first-banner-inner {
  /* display: flex; */
  /* align-items: center; */
  height: 100%;
}
.first-banner .row {
  align-items: center;
  height: calc(100vh - 5px);
  overflow: hidden;
}
.first-banner-inner .swiper-wrapper {
  align-items: end;
}
.swiper-slide.swiper-slide-visible img {
  height: 714px;
  object-fit: contain;
}

.first-banner .row > div:last-child {
  align-self: flex-end;
  margin-top: 0px;
}

.banner1-content h1 {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
  line-height: 64px;
  margin: 0;
}

.banner1-content h1 span {
  font-family: "Comfortaa", cursive;
  font-weight: 700;
  position: relative;
  display: block;
}

.banner1-content p {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin: 30px auto;
}

.banner1-content .banner1-btn {
  display: flex;
  align-items: center;
  gap: 70px;
  margin-top: 30px;
}
.btn-green {
  background: var(--green);
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  padding: 12px 30px;
}
.btn-green:hover {
  border-color: var(--blue);
}
.btn-green::before {
  background-color: var(--blue);
}

.banner1-btn .works-btn {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.banner1-btn .works-btn img {
  filter: brightness(100);
  transition: 0.3s ease all;
}
.banner1-btn .works-btn.icon-btn:hover img {
  transform: translateX(5px);
}

@media (max-width: 1280px) {
  .banner1-content h1 {
    font-size: 48px;
    line-height: 64px;
  }
}
/* first banner css============================ */
/* second banner css============================ */
.second-banner {
  padding: 120px 0 210px 0;
}

.second-banner .banner2-content .story {
  text-transform: uppercase;
  color: #acc8f0;
  font-weight: 400;
  font-size: 16px;
}
.banner2-image {
  position: relative;
}

.banner2-image .b-pattern {
  position: absolute;
  right: calc(100% - 420px);
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}
.banner2-content h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  color: #000;
  margin-bottom: 30px;
}

.banner2-content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.banner2-content p:not(:last-child) {
  margin-bottom: 40px;
}

.banner2-content .read-more-btn {
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}

.banner2-content .read-more-btn:hover {
  background: #b8f6e5;
  color: black;
}
.banner2-content {
  max-width: 460px;
  margin-right: 0;
  margin-left: auto;
}

/* second banner css============================ */
/* third banner css============================= */
.third-banner {
  padding: 150px 0 0px;
  display: flex;
  align-items: center;
  position: relative;
}
section.second-banner > .container > .row {
  align-items: center;
}
.banner3-content .resone {
  font-size: 16px;
  font-weight: 400;
  color: #818181;
  text-transform: uppercase;
}
.arrow-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.blue-wraper {
  position: absolute;
  bottom: 30px;
  z-index: -1;
  right: 17%;
}
.banner3-content h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 60px;
}

.company-info1 {
  border-radius: 10px;
  width: 100%;
  padding: 1px;
  background: linear-gradient(
    0deg,
    rgba(26, 200, 156, 1) 8%,
    rgba(9, 93, 213, 1) 100%
  );
}
.banner3-content {
  max-width: 948px;
  margin: 0 auto;
}
.banner3-content .company-info1 .info {
  padding: 19px 30px;
  display: flex;
  align-items: center;
  background: #f4fcff;
  border-radius: 10px;
}
.rightDiv,
.leftDiv {
  display: flex;
  gap: 15px;
  align-content: center;
  flex-wrap: wrap;
  max-width: calc(100% / 2 - 50px);
}
.wrapper-flex {
  gap: 20px 100px;
}
.gradient-wraper {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

section.second-banner {
  position: relative;
}
.arrow-container img {
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
@keyframes bounce {
  50% {
    transform: translateY(-15px);
  }
}
.gr-blur {
  position: absolute;
  z-index: -1;
  top: -150px;
  width: 100%;
  height: 100%;
  left: 230px;
}
/* third banner css============================= */
/* fourth section=================================== */
section.fourth {
  padding-bottom: 70px;
}
.fourth .process {
  text-transform: uppercase;
  color: #818181;
  font-size: 16px;
  font-weight: 400;
}

.fourth h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 64px;
  margin-bottom: 20px;
}
.start-map-content .mt-lg-0 {
  margin-bottom: 20px;
}

.start-map-content .mt-lg-0 + div p {
  max-width: 280px;
}

.start-map-content > .row {
  width: 100%;
}
/* .start-map {
  display: flex;
  justify-content: space-around;
  position: relative;
} */
.start-map-content {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.start-map-content::before {
  content: "";
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background: url(./Components/assets/dotted-line.svg);
  background-size: cover;
  width: 70%;
  height: 1px;
  margin: 0 auto;
  z-index: -1;
}

.start-map-content h3 {
  font-size: 24px;
  font-weight: 600;
}

.start-map-content p {
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #4e4e4e;
}

/* fourth section=================================== */
/* fifth section=============================== */
.fifth {
  background: #f5f9ff;
  padding: 80px 0;
}

.fifth .non-emergency {
  color: #818181;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
}

.fifth h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
}

.fifth .health {
  font-weight: 500;
  font-size: 20px;
}
.img-fluid img {
  width: 100%;
}
.healthbox-border {
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(180deg, #095dd5 0%, #81f0d3 75%);
}

.health-box {
  border-radius: 8px;
  padding: 10px 20px;
  background: linear-gradient(180deg, #d0e4ff 0%, #ffffff 100%);
  display: flex;
  align-items: center;
}
.box-cstm {
  max-width: calc(100% / 4);
  transition: 0.3s ease all;
}
.box-cstm:hover {
  transform: translateY(-5px);
}
.available-data {
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 30px;
}
.available-data h2 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 30px;
}
.petSection {
  padding: 75px 0 0;
}
.petSection .img-fluid {
  margin-bottom: -46px;
}
.petSection :where(.blue-img, .pattern-left) {
  left: 0;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
}
.petSection .blue-img {
  left: 40px;
  max-width: 650px;
}

@media (min-width: 991px) {
  .box-cstm:not(:nth-last-child(-n + 4)) .healthbox-border {
    margin-bottom: 24px;
  }
}

/* fifth section=============================== */

/* form section================================= */
.form {
  background: #e4efff;
  padding: 80px 0;
}

.form .message {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.form h1 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.chat-box {
  background: #004dba;
  border: 1px solid #237eff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 40px;
  gap: 20px;
}

.chat-box p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;
}

.form label {
  color: #4e4e4e;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}

.form form input {
  width: 100%;
  background: #ffffff;
  border: 2px solid #cecece;
  border-radius: 5px;
  outline: none;
  padding: 5px 15px;
  color: #242f40;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
}

.form form textarea {
  width: 100%;
  background: #ffffff;
  border: 2px solid #cecece;
  border-radius: 5px;
  outline: none;
  padding: 5px 15px;
  color: #242f40;
  font-size: 16px;
  font-weight: 500;
  resize: none;
}

.form .submit-btn {
  background: var(--green);
  border-color: var(--green);
  border-radius: 8px;
  padding: 10px 60px;
  color: #fff;
  min-width: 240px;
  min-height: 64px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
}
.contact-form {
  max-width: 980px;
  margin: 80px auto 0;
}
.form-group {
  margin-bottom: 8px;
}
/* privacy css============================== */
/* .privacy {
  margin-top: 100px;
} */

.privacy hr {
  height: 1px;
  background: linear-gradient(
    90deg,
    #9647fa 5.66%,
    #1875f8 48.9%,
    #1acc96 100%
  );
  margin-bottom: 20px;
}

.privacy .arrow-right {
  font-size: 26px;
  color: #dc392d;
  line-height: 20px;
}

.privacy-content .privacy-para {
  color: rgb(13, 27, 54);
  font-size: 18px;
  font-weight: 300;
}

.policy-heading {
  background: linear-gradient(157deg, #9647fa 15%, #1875f8 30%, #1acc96 75%),
    url(./Components/assets/provider.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: 150px;
  padding-bottom: 60px;
  text-align: center;
}

.privacy-content h4 {
  font-size: 24px;
  color: #2751a3;
  font-weight: 700;
}

@media (max-width: 767px) {
  /* .privacy {
    margin-top: 80px;
  } */

  .privacy-content .heading {
    font-size: 35px;
  }

  .privacy .arrow-right {
    font-size: 18px;
    color: #dc392d;
    line-height: 20px;
  }

  .privacy-content h4 {
    font-size: 14px;
  }

  .privacy-content .privacy-para {
    font-size: 14px;
  }
}

/* privacy css============================== */

/* About css==================================== */

.about h1 {
  color: #1976f9;
  font-size: 40px;
  font-weight: 700;
}

.about-content {
  margin: 0 auto;
}

.about-para {
  color: rgb(13, 27, 54);
  font-size: 16px;
  font-weight: 300;
}

.provider-content {
  margin: 0 auto;
}

.about-banner {
  background: linear-gradient(157deg, #9647fa 15%, #1875f8 30%, #1acc96 75%),
    url(./Components/assets/provider.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: 150px;
  padding-bottom: 60px;
  text-align: center;
}

.about-banner h1 {
  color: #fff;
  font-weight: 500;
  font-size: 50px;
}

.medical-info {
  border: 2px solid #1ac79d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 20px;
}

.provider {
  background: url("./Components/assets/bg-1.png");
  background-position: 100% 100%;
  background-size: 60% 100%;
  background-repeat: no-repeat;
}

@media (max-width: 1300px) {
  .about-para {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .about-banner {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  .provider .row {
    flex-direction: column-reverse;
  }

  .about h1 {
    font-size: 30px;
  }

  .about-banner h1 {
    font-size: 26px;
  }
}

/* About Css=================================== */

/* form section================================= */
/* footer css=============================== */
footer {
  background: #0e2845;
  padding-top: 79px;
  padding-bottom: 79px;
  padding-left: 15px;
  padding-right: 15px;
}
.form h2 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 0;
}
footer .heading {
  color: #d7d7d7;
  font-size: 17px;
  font-weight: 600;
}

footer .address li,
.address a {
  color: #c5cddd;
  font-size: 16px;
  font-weight: 400;
}

footer li a {
  font-size: 16px;
  font-weight: 300;
  color: #c5cddd;
}

footer a:hover {
  color: #fff;
  padding-left: 15px;
}
footer a::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 2px;
  background: linear-gradient(
    90deg,
    #9647fa 5.66%,
    #1875f8 48.9%,
    #1acc96 100%
  );
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: 0.3s ease all;
}

footer a:hover::before {
  width: 10px;
}
ul.icons-payment {
  max-width: 162px;
}

.sub-footer {
  background: #0e2845;
  border-top: 1px solid #3e4a67;
  padding: 20px 15px;
}
.copyright {
  flex: 1;
  text-align: center;
}
.sub-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1095px;
  margin: auto;
}
.sub-footer .links a,
.sub-footer .copyright p {
  font-weight: 400;
  font-size: 14px;
  color: #8a94ab;
}

footer a {
  position: relative;
  display: inline-block;
  transition: 0.3s ease all;
}

.sub-footer .links a {
  position: relative;
  display: inline-block;
}

.sub-footer .links a::before {
  transform: scaleX(0);
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    #9647fa 5.66%,
    #1875f8 48.9%,
    #1acc96 100%
  );
  bottom: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.sub-footer .links a:hover::before {
  transform: scaleX(1);
}
.social a:hover {
  transform: scale(1.2);
}

.social a {
  transition: 0.3s ease all;
}
.links p {
  color: #c5cddd;
  font-weight: 300;
  font-size: 14px;
}
.red-heart {
  color: #eb3223;
}
/* footer css=============================== */

.text-green {
  color: #1ac89c;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  background: linear-gradient(90deg, #1875f8 48.9%, #1acc96 100%);
  height: 10px;
  border-top: 0 !important;
  opacity: 1;
}

.fw-700 {
  font-weight: 700 !important;
}

.policy-heading h2 {
  font-size: 50px;
}

.privacy h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.privacy .privacy-para {
  overflow-wrap: break-word;
}

.chat-box p a {
  text-decoration: underline;
}
@media (max-width: 991px) {
  .policy-heading h2 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .logo-img {
    height: 40px;
  }

  .policy-heading h2 {
    font-size: 26px;
  }

  .privacy h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .banner1-content h1 {
    font-size: 30px;
    line-height: 45px;
  }

  .second-banner {
    padding: 50px 0;
  }

  .banner3-content h1 {
    font-size: 26px;
  }

  .banner3-content .company-info1 {
    width: 100%;
  }

  .fifth h1 {
    font-size: 20px;
    line-height: 22px;
  }

  .chat-box {
    padding-left: 15px;
    gap: 8px;
  }

  .chat-box p {
    font-size: 14px;
  }

  .icons-payment img {
    width: 100%;
  }
  .sub-footer-content {
    flex-direction: column;
    justify-content: center;
  }

  .collapsing {
    height: 0 !important;
    transition: none !important;
  }

  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .appoint-img {
    display: none;
  }
}

.transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.appoint-form {
  display: flex;
}

.appoint-img {
  left: 0;
  top: 0;
  background: #f4fcff;
  border-radius: 5px 0px 0px 5px;
}

.appoint-fields {
  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
}

.appoint-fields h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #7a61fb;
}

.appoint-fields p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #243239;
}

.appoint-fields form .form-group {
  margin: 15px 0px;
}

.appoint-fields form .form-group .form-label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000;
}

.appoint-fields form .form-group input {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;
}

/* .appoint-img img {
  width: 100%;
  height: 100%;
} */

.ReactModal__Overlay {
  z-index: 10000;
}

.ReactModal__Content {
  width: 80%;
  height: auto;
  margin: auto;
}

.css-13cymwt-control {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
}

.modal-buttons {
  text-align: end;
}

.btn-x {
  background: #ffffff;
  border: 1px solid #7a61fb;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  color: #7a61fb;
  padding: 11px 23px;
}

.btn-submit {
  margin-left: 20px;
  background: #7a61fb;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #ffffff;
  padding: 11px 23px;
}

.btn-submit:disabled {
  background: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.available-data p {
  margin-bottom: 30px;
}
p.down-heading {
  color: #c5cddd;
  font-weight: 700;
}
.footer-logo {
  margin-bottom: 55px;
}
ul.address p:not(.down-heading) {
  color: #3eccff;
  font-weight: 300;
  line-height: 28px;
}
.footer-links li:not(:last-child) {
  margin-bottom: 13px;
}
@media screen and (max-width: 480px) {
  .appoint-fields h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
  }

  .appoint-fields form .btn-x,
  .appoint-fields form .btn-submit {
    padding: 6px 12px;
  }
}

/* responsive design */

@media (min-width: 767px) {
  .mb-arrow {
    display: none;
  }
}
@media (min-width: 1025px) {
  .navbar-expand-lg .navbar-nav {
    /* margin: 0 auto; */
    margin-left: auto;
  }
}
@media (max-width: 1600px) {
  .banner2-image .b-pattern {
    right: 100px;
  }
}
@media (max-width: 1440px) {
  .sub-footer-content {
    max-width: 73vw;
  }
}
@media (max-width: 1280px) {
  .first-banner {
    height: inherit;
  }
  .banner2-image .b-pattern {
    height: 250px;
    max-width: 300px;
    right: calc(100% - 230px);
  }
  .second-banner {
    padding: 80px 0 210px 0;
  }
  .third-banner {
    padding: 80px 0 0px;
  }
  .health-box {
    font-size: 14px;
  }
  .sub-footer-content {
    max-width: 79vw;
  }
  .banner1-content h1 {
    font-size: 40px;
    line-height: 64px;
    max-width: 500px;
  }
  .fourth h2,
  .banner3-content h2,
  .banner2-content h2,
  .fifth h2,
  .form h2,
  .available-data h2 {
    font-size: 32px;
  }
  .contact-form {
    margin: 50px auto 0;
  }
}
@media (max-width: 1199px) {
  .banner1-content h1 {
    font-size: 36px;
    line-height: 57px;
  }
  .banner2-image .b-pattern {
    right: calc(100% - 260px);
  }

  .wrapper-flex {
    gap: 20px 50px;
  }
  .sub-footer-content {
    max-width: 85vw;
  }
  .gr-blur {
    max-width: 250px;
    height: 300px;
    top: -40px;
  }
  .blue-wraper {
    bottom: 0;
  }
  .blue-wraper img {
    max-width: 80%;
  }

  .petSection .img-fluid {
    margin-bottom: -34px;
  }
  .banner2-content p:not(:last-child) {
    margin-bottom: 25px;
  }
  .petSection {
    padding: 100px 0 0;
  }
  .banner1-content .banner1-btn {
    gap: 50px;
  }
}

@media (max-width: 991px) {
  .banner1-content p {
    margin: 20px auto 0;
  }
  .first-banner .row {
    /* flex-direction: column-reverse; */
    gap: 60px;
  }
  .banner2-content {
    max-width: 100%;
  }
  .banner2-content h2,
  .banner2-content p:not(:last-child) {
    margin-bottom: 15px;
  }
  .wrapper-flex {
    gap: 15px;
  }

  .banner1-content .banner1-btn {
    gap: 20px;
  }
  .banner3-content .company-info1 .info {
    height: 100%;
    padding: 15px;
  }

  .gr-blur {
    display: none;
  }
  .box-cstm {
    max-width: calc(100% / 2);
  }
  .health-problems > .row {
    gap: 20px 0;
  }
  .banner2-image img {
    max-width: 480px;
    margin: 0 auto;
    display: block;
  }
  .banner2-image .b-pattern {
    right: calc(100% - 300px);
  }

  section.second-banner > .container > .row {
    gap: 40px 0;
  }
  .banner3-content h2 {
    margin-bottom: 30px;
  }
  .start-map-content h3 {
    margin-bottom: 15px;
  }
  .start-map-content > .row {
    gap: 60px 0;
  }
  .start-map-content h3 {
    margin-bottom: 15px;
  }

  .start-map-content::before {
    transform: rotate(90deg) translateY(-50%);
    top: 50%;
  }

  .start-map-content > .row > [class*="col-lg"] {
    background: #fff;
    padding: 10px 0;
  }

  .chat-box {
    padding: 10px;
    gap: 8px;
  }

  .chat-box p {
    font-size: 14px;
    line-height: normal;
  }

  .chat-box p:not(:last-child) {
    margin-bottom: 8px;
  }

  .third-banner,
  section.second-banner,
  .petSection {
    padding-top: 60px;
  }
  .petSection .img-fluid {
    margin-bottom: -26px;
  }
  .btn {
    font-size: 16px;
  }
  .contact-form {
    margin: 30px auto 0;
  }
  .form .submit-btn {
    min-width: 220px;
    min-height: 60px;
    font-size: 16px;
  }
  .footer-logo {
    margin-bottom: 10px;
  }
  p.down-heading {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  ul.icons-payment li {
    max-width: calc(100% / 2 - 4px);
    flex: calc(100% / 2 - 4px);
    width: 100%;
  }
  ul.icons-payment {
    margin-top: 20px;
    max-width: 340px;
  }
  .fifth,
  footer,
  .form {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .rightDiv,
  .leftDiv {
    max-width: calc(100% / 2 - 20px);
  }
}

@media (max-width: 767px) {
  .fifth h2 br,
  .desktop-arrow,
  .fourth h2,
  .paraheader {
    display: none;
  }
  .health-box {
    padding: 10px;
  }
  .start-map-content::before {
    width: 100%;
    height: 2px;
  }
  .first-banner {
    background: url("./Components/assets/mobile-bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 120px 0 100px;
  }
  .banner1-content h1,
  .banner2-content h1 {
    font-size: 32px;
    line-height: 48px;
  }
  .banner2-image img {
    max-width: 250px;
  }
  .banner2-image .b-pattern {
    left: 0;
    right: auto;
    height: 170px;
    max-width: 250px;
  }
  .banner3-content .company-info1 {
    width: 100%;
    max-width: 100%;
  }

  .banner3-content .company-info1 .info span {
    font-size: 14px;
  }
  .third-banner {
    padding-top: 100px;
  }
  .petSection .pattern-left img,
  .gradient-wraper img {
    max-width: 195px;
    opacity: 0.4;
  }
  .arrow-container img {
    height: 100px;
  }
  .second-banner {
    padding-bottom: 160px;
  }
  .arrow-container {
    bottom: 20px;
  }

  .Behavioral-health .health {
    text-align: left !important;
  }
  .fifth h2,
  .available-data h2 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .health-box img {
    max-width: 32px;
    margin-right: 10px !important;
  }
  .health-problems > .row {
    gap: 10px 0;
  }
  .box-cstm:nth-child(2n) {
    padding-left: 0;
  }
  .health-problems > .row .box-cstm {
    max-width: 100%;
  }
  .health-problems > .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .healthbox-border {
    height: 100%;
    display: grid;
    border-radius: 8px;
  }
  .box-cstm:hover {
    transform: unset;
  }
  .fifth .health {
    margin-top: 40px !important;
  }
  .petSection .img-fluid {
    margin-bottom: -14px;
  }
  .available-data {
    padding-bottom: 60px;
  }
  .contact-form > .row {
    gap: 10px;
    margin-bottom: 30px;
  }
  .form .submit-btn {
    min-width: 100%;
    margin-top: 15px;
  }

  footer ul {
    margin-top: 40px;
  }
  .sub-footer {
    padding: 30px 15px;
  }
  .banner1-btn .works-btn {
    font-size: 16px;
  }
  .banner3-content h2 {
    text-align: left;
  }
  .rightDiv,
  .leftDiv {
    max-width: 100%;
  }
  .wrapper-flex {
    gap: 40px;
  }
}

.iconLink:hover {
  color: inherit;
  padding-left: 0;
}

.iconLink::before {
  content: none;
}

.iconLink:hover::before {
  width: 0;
}

.iconLink {
  height: 50px;
}

/* 27 sep 2024 starts */
/* .service-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.service-grid .service-card {
  width: calc(100% / 2 - calc(20px / 2));
  position: relative;
}

.service-grid .service-card img {
  height: 280px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.service-grid .service-card-content {
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-right-radius: 48px;
  background-color: #fff;
  padding: 12px 20px 0 0;
  max-width: 280px;
}

.service-grid .service-card-content h3 {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 1.33;
}

.service-grid .service-card-content h3 span {
  padding-right: 4px;
}

.service-grid .service-card-content p {
  font-size: 14px;
  line-height: 1.5;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 12px;
}

.service-card-buttons {
  display: flex;
  gap: 16px;
}

.service-card-buttons .learn-more-btn {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--green);
  transition: all ease-in-out 0.15s;
}

.service-card-buttons .learn-more-btn:hover {
  color: var(--blue);
}
27 sep 2024 ends */

ul.navbar-nav li {
  cursor: pointer;
}

body.services-hover ul.navbar-nav li svg + div {
  height: 77vh;
  padding: 18px 15px;
}
body.services-hover ul.navbar-nav li svg {
  transform: rotateX(180deg);
}

body.services-hover .swiper.swiper-fade.swiper-initialized {
  opacity: 0;
}

.first-banner .first-banner-inner .swiper-slide {
  padding-top: 113%;
  overflow: hidden;
  position: relative;
  height: 0;
  display: block;
}

.first-banner .first-banner-inner .swiper-slide img {
  position: absolute;
  top: 0;
  height: 714px;
  object-fit: contain;
}
.heroBanner .textBlock {
  width: 50%;
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
}
.heroBanner .docterImages {
  width: 50%;
  float: right;
  height: 100%;
  /* display: flex; */
}
.heroBanner .docterImages .swiper.swiper-fade {
  display: flex;
  height: 100%;
}
.first-banner .first-banner-inner .container {
  height: 100%;
}
.heroBanner {
  height: 100%;
  display: flex;
  align-items: end;
}
/* .heroBanner .textBlock {
  width: 50%;
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
}

.heroBanner .docterImages {
  width: 50%;
  float: right;
  height: 100%;
  align-items: flex-end;
  display: flex;
}
.first-banner .first-banner-inner .container {
  height: 100%;
}
.heroBanner {
  height: 100%;
  display: flex;
  align-items: end;
} */

@media screen and (max-width: 1440px) {
  .first-banner .first-banner-inner .swiper-slide {
    padding-top: 110%;
  }
}

@media screen and (max-width: 1280px) {
  html body .first-banner {
    height: 100vh !important;
  }
}

@media screen and (max-width: 1199px) {
  .first-banner .first-banner-inner .swiper-slide {
    padding-top: 139%;
  }
}
@media screen and (max-width: 992px) {
  .heroBanner {
    flex-direction: column;
  }
  .heroBanner .textBlock {
    width: 100%;
  }
  .heroBanner .docterImages {
    width: 100%;
    float: right;
  }
  .first-banner {
    padding-top: 100px;
  }
  .first-banner .first-banner-inner .swiper-slide {
    padding-top: 100%;
  }
}

@media screen and (max-width: 767px) {
  .first-banner .first-banner-inner .swiper-slide {
    padding-top: 130%;
  }
  .first-banner {
    padding-top: 100px;
    padding-bottom: 0;
  }
  /* .servicesModal {
    height: 100vh;
    overflow: auto !important;
  } */
}

@media screen and (max-width: 575px) {
  .first-banner .first-banner-inner .container {
    height: 100%;
    max-width: 540px;
  }
  .heroBanner {
    width: 100%;
    max-width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 425px) {
  .first-banner .first-banner-inner .container {
    height: 100%;
    max-width: 340px;
  }
  .heroBanner {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
  .first-banner .first-banner-inner .swiper-slide {
    padding-top: 175%;
  }
}

.ReactModal__Content {
  overflow-y: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-hover {
  /* overflow: hidden; */
}
.services-hover ul.navbar-nav li:hover svg + div {
  height: 77vh;
  padding: 18px 15px;
  z-index: 2;
  overflow: auto;
}

@media screen and (max-width: 992px) {
  .services-hover ul.navbar-nav li:hover svg + div {
    height: 77vh;
    padding: 18px 15px;
    z-index: 2;
    overflow: auto;
  }
}
